import { PropsWithChildren, useState } from 'react';
import { intlFormat } from 'date-fns';
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import HasAccess from 'permissions/HasAccess';
import { PaymentStatus } from 'payment/components/PaymentStatus';
import { DeletePaymentDialog } from 'components/Dialogs/DeletePaymentDialog';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Currency } from 'currency/components/Currency';
import paymentProviderMap from 'payment/paymentProviderMap';
import { PAYMENT_STATUS_CREATED, PAYMENT_STATUS_VOIDED } from 'invoices/InvoiceStatuses';
import { Permission, UserRole } from 'permissions/constants/Roles';
import {
  IconButton,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import totals, { itemsWithTotal } from 'payment/totals';
import { getInvoiceBalanceDue, getInvoiceTaxAmount } from 'invoices/invoiceUtils';
import currency from 'currency/currency';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { defaultTaxRateNz, taxRates } from './taxRates';
import { Payment } from 'types/Invoice';
import { useLocale } from 'contexts/LocaleContext';
import { useSchool } from 'contexts/SchoolContext';

const CELL_WIDTH_REORDER = 5;
const CELL_WIDTH_DESCRIPTION = 29;
const CELL_WIDTH_QUANTITY = 15;
const CELL_WIDTH_AMOUNT = 12;
const CELL_WIDTH_TAX_RATE = 12;
const CELL_WIDTH_TOTAL = 12;
const CELL_WIDTH_DELETE = 5;

interface SortableRowProps {
  id: string;
  index: number;
  disabled: boolean;
  isDraggable: boolean;
}

const SortableRow = ({ index, disabled, isDraggable, children }: PropsWithChildren<SortableRowProps>) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: index, disabled: disabled || !isDraggable });
  const dragAnimationSx: SxProps = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const dragHandleSx: SxProps = {
    cursor: isDragging ? 'grabbing' : 'grab',
    textAlign: 'center',
  };
  return (
    <TableRow ref={setNodeRef} sx={dragAnimationSx}>
      {!disabled && (
        <TableCell width={`${CELL_WIDTH_REORDER}%`} sx={dragHandleSx} {...attributes} {...listeners}>
          {isDraggable && <DragHandleIcon sx={{ color: (theme) => theme.palette.grey[600] }} />}
        </TableCell>
      )}
      {children}
    </TableRow>
  );
};

interface InvoiceTableManualProps {
  asStatement: boolean;
  disabled: boolean;
  canAddPayments: boolean;
  onDeletePayment: () => void;
  invoice: any;
  onChangeDescription: (newValue: string, index: number) => void;
  onChangeAmount: (newValue: string, index: number) => void;
  onChangeQuantity: (newValue: number, index: number) => void;
  onChangeTaxRate: (newValue: number, index: number) => void;
  onRemoveRow: (index: number) => void;
  onChangeRowOrder: (oldIndex: number, newIndex: number) => void;
}

export const InvoiceTableManual = ({
  asStatement,
  disabled,
  canAddPayments,
  onDeletePayment,
  invoice,
  onChangeDescription,
  onChangeAmount,
  onChangeQuantity,
  onChangeTaxRate,
  onRemoveRow,
  onChangeRowOrder,
}: InvoiceTableManualProps) => {
  const [paymentToDelete, setPaymentToDelete] = useState<Payment | null>(null);
  const [showDeletePaymentDialog, setShowDeletePaymentDialog] = useState(false);
  const { locale, localeCode } = useLocale();
  const {
    state: { school },
  } = useSchool();
  const currencyCode = school?.currency_code || 'NZD';
  const total = totals(invoice.items, currencyCode, locale);
  const items = itemsWithTotal(invoice.items, currencyCode, locale);
  const invoiceTax = getInvoiceTaxAmount(invoice.items, currencyCode, locale);
  const invoiceTaxFormatted = currency(invoiceTax, currencyCode, locale);
  const subtotal = currency(total - invoiceTax, currencyCode, locale);
  const invoiceBalanceDueFormatted = currency(getInvoiceBalanceDue(invoice, currencyCode, locale), currencyCode, locale);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const oldIndex = active.id as number;
    const newIndex = over.id as number;

    // Prevent re-order of first row of the table
    if (newIndex !== 0) {
      onChangeRowOrder(oldIndex, newIndex);
    }
  };

  const openDeletePaymentDialog = (payment: Payment) => () => {
    setPaymentToDelete(payment);
    setShowDeletePaymentDialog(true);
  };

  const renderRow = (row, index, showTax, descriptionWidth) => {
    const taxRate = row.tax_rate === undefined ? defaultTaxRateNz : parseFloat(row.tax_rate);
    return (
      <>
        <TableCell width={descriptionWidth}>
          <TextField
            fullWidth
            size="small"
            value={row.description}
            placeholder={index === 0 ? 'Tuition' : 'Description'}
            onChange={(event) => onChangeDescription(event.target.value, index)}
            data-cy={`invoice-table-description`}
            disabled={disabled}
            required
          />
        </TableCell>
        <TableCell width={`${CELL_WIDTH_QUANTITY}%`}>
          <TextField
            fullWidth
            type="number"
            size="small"
            value={row.quantity ? parseFloat(row.quantity).toFixed(2) : '0'}
            onChange={(event) => onChangeQuantity(parseInt(event.target.value), index)}
            inputProps={{ min: 0 }}
            data-cy={`invoice-table-quantity`}
            disabled={disabled}
            required
          />
        </TableCell>
        <TableCell width={`${CELL_WIDTH_AMOUNT}%`}>
          <TextField
            fullWidth
            type="number"
            size="small"
            value={row.amount}
            onChange={(event) => onChangeAmount(event.target.value, index)}
            data-cy={`invoice-table-amount`}
            disabled={disabled}
            required
          />
        </TableCell>
        {showTax &&
          (disabled ? (
            <TableCell width={`${CELL_WIDTH_TAX_RATE}%`}>{`${taxRate.toFixed(2)}%`}</TableCell>
          ) : (
            <TableCell width={`${CELL_WIDTH_TAX_RATE}%`}>
              <Select
                variant="outlined"
                fullWidth
                data-cy="tax-rate-dropdown"
                size="small"
                value={taxRate}
                disabled={disabled}
                onChange={(event) => onChangeTaxRate(event.target.value as number, index)}
              >
                {taxRates.map((rate) => (
                  <MenuItem key={rate} value={rate}>
                    {rate}%
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
          ))}
        <TableCell data-cy="row-total" width={`${CELL_WIDTH_TOTAL}%}`}>
          {row.total && <Currency amount={row.total} />}
        </TableCell>
        {!disabled && (
          <TableCell data-cy="delete-row-button" width={`${CELL_WIDTH_DELETE}%}`}>
            <HasAccess for="permission" name={[Permission.CreateInvoice]}>
              {index !== 0 && (
                <Tooltip title="Remove row">
                  <IconButton onClick={() => onRemoveRow(index)}>
                    <DeleteOutlineIcon color="error" />
                  </IconButton>
                </Tooltip>
              )}
            </HasAccess>
          </TableCell>
        )}
      </>
    );
  };

  const renderRows = (showTax, descriptionWidth) => {
    if (!disabled) {
      return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={items.map((_, index) => index)} strategy={verticalListSortingStrategy}>
            {items.map((row, index) => (
              <SortableRow key={row.id} id={row.id} index={index} disabled={disabled} isDraggable={index !== 0}>
                {renderRow(row, index, showTax, descriptionWidth)}
              </SortableRow>
            ))}
          </SortableContext>
        </DndContext>
      );
    }
    return items.map((row, index) => <TableRow key={row.id}>{renderRow(row, index, showTax, descriptionWidth)}</TableRow>);
  };

  const renderPaymentsAndBalanceDue = () => {
    const excludedStatuses = [PAYMENT_STATUS_CREATED, PAYMENT_STATUS_VOIDED];
    const validPayments = invoice.payments.filter((payment) => !excludedStatuses.includes(payment.status));

    if (!asStatement || !validPayments.length) {
      return <></>;
    }

    const paymentRows = validPayments.map((payment) => {
      const amount = currency(payment.amount, currencyCode, locale);
      const paymentProvider = paymentProviderMap[payment.payment_provider.slug];
      const Icon = paymentProvider.iconSmall;
      return (
        <TableRow key={payment.id}>
          <TableCell data-cy="payment-date" width="15%">
            <Typography>{payment.payment_date ? intlFormat(new Date(payment.payment_date), { locale: localeCode }) : ''}</Typography>
          </TableCell>
          <TableCell width="37%">
            <Tooltip title={payment.payment_provider.name}>
              <Stack direction="row" alignItems="center">
                <Icon className="icon icon--payment" />
                <Typography>{payment.payment_provider.name} payment</Typography>
              </Stack>
            </Tooltip>
          </TableCell>
          <TableCell data-cy="payment-status" width="28%">
            <PaymentStatus status={payment.status} />
          </TableCell>
          <TableCell data-cy="payment-amount" width="15%">
            <Typography>{amount}</Typography>
          </TableCell>
          {canAddPayments && (
            <HasAccess for="role" name={[UserRole.InstitutionalStaff, UserRole.InstitutionalAdmin]}>
              <TableCell width="5%">
                {paymentProvider.canDeletePayments && (
                  <Tooltip title="Remove payment">
                    <IconButton onClick={openDeletePaymentDialog(payment)}>
                      <DeleteOutlinedIcon color="error" />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </HasAccess>
          )}
        </TableRow>
      );
    });

    return (
      <>
        <Typography variant="h5" component="h3" mt={4} mb={1}>
          Payment(s) made
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell colSpan={2}>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{paymentRows}</TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3}>
                <Typography fontWeight="bold" textAlign="right">
                  Balance due
                </Typography>
              </TableCell>
              <TableCell data-cy="balance-due">
                <Typography>{invoiceBalanceDueFormatted}</Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </>
    );
  };

  // If the tax is zero hide the column and subtotal rows. Used for UpEd
  const showTax = !disabled || invoiceTax > 0;

  // Grow description width to account for missing columns
  const descriptionWidth = CELL_WIDTH_DESCRIPTION + (showTax ? 0 : CELL_WIDTH_TAX_RATE);

  // Add column spans to subtotal line spacer for Tax Rate and Reorder columns if they're visible
  const addNewLineSpan = 2 + (showTax ? 1 : 0) + (disabled ? 0 : 1);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {!disabled && <TableCell width={`${CELL_WIDTH_REORDER}%`}>Reorder</TableCell>}
            <TableCell width={descriptionWidth}>Description</TableCell>
            <TableCell width={`${CELL_WIDTH_QUANTITY}%`}>Quantity</TableCell>
            <TableCell width={`${CELL_WIDTH_AMOUNT}%`}>Amount</TableCell>
            {showTax && <TableCell width={`${CELL_WIDTH_TAX_RATE}%`}>Tax Rate</TableCell>}
            <TableCell width={`${CELL_WIDTH_TOTAL}%`}>Total</TableCell>
            {/* Placeholder for remove row button column */}
            {!disabled && <TableCell width={`${CELL_WIDTH_DELETE}%`} />}
          </TableRow>
        </TableHead>

        <TableBody>{renderRows(showTax, descriptionWidth)}</TableBody>

        <TableFooter>
          {showTax && (
            <>
              <TableRow>
                <TableCell sx={{ border: 0 }} colSpan={addNewLineSpan} rowSpan={2} />
                <TableCell sx={{ border: 0 }}>
                  <Typography textAlign="right" fontWeight="500">
                    Subtotal
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: 0 }} data-cy="invoice-sub-total">
                  <Typography>{subtotal}</Typography>
                </TableCell>
                {/* Placeholder for tax rate column */}
                {!disabled && <TableCell sx={{ border: 0 }} />}
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography textAlign="right" fontWeight="500">
                    Includes GST
                  </Typography>
                </TableCell>
                <TableCell data-cy="invoice-tax">
                  <Typography>{invoiceTaxFormatted}</Typography>
                </TableCell>
                {/* Placeholder for remove row button column */}
                {!disabled && <TableCell />}
              </TableRow>
            </>
          )}
          <TableRow>
            <TableCell colSpan={addNewLineSpan}></TableCell>
            <TableCell>
              <Typography textAlign="right" fontWeight="500">
                Total {currencyCode}
              </Typography>
              <Typography textAlign="right" fontSize="small">
                {showTax && ' (GST inclusive) '}
              </Typography>
            </TableCell>
            <TableCell data-cy="invoice-total">
              <Typography>
                <Currency amount={total} />
              </Typography>
            </TableCell>
            {/* Placeholder for remove row button column */}
            {!disabled && <TableCell />}
          </TableRow>
        </TableFooter>
      </Table>
      {renderPaymentsAndBalanceDue()}
      <DeletePaymentDialog
        payment={paymentToDelete}
        active={showDeletePaymentDialog}
        onDeletePayment={onDeletePayment}
        onClose={() => setShowDeletePaymentDialog(false)}
      />
    </>
  );
};
