// prettier-ignore
export const taxRates = [
  15,     /** GST in NZ  */
  12.85,  /** @see ER-3853 Christ's College, boarding fee  */
  12.29,  /** @see ER-2504, no documented reason for request  */
  11.45,  /** @see B2B-603, CBHS requested tax rate */
  11.24,  /** @see ER-3092 requested by school  */
  10.6,   /** @see ER-2724, accountant for school said this was necessary?  */
  10,     /** GST in AU  */
  0,      /** No tax  */
];

export const defaultTaxRateNz = taxRates[0];
